import React from 'react'
import { Link } from 'gatsby'

export default function Logo() {
  return (
    <div>
      <Link to={`/`} className="flex bg-white p-1">
        <div className="w-1/2 pr-0.5">
          <svg id="e8cb1473-bf69-4a7a-8f31-091d4f39d85a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 109.03 108.22">
            <rect y="0.22" width={108} height={108} style={{ fill: '#016a3f' }} />
            <path
              d="M265.21,254.14H250.57c0-11,5.85-17.67,10.54-23,4.39-5,7.86-9.05,7.86-16.18,0-4.62-8-11.46-14.24-13.56a7.42,7.42,0,0,1,0-14c5.92-2,14-8,14-15.06,0-2.88.64-11.64-23.36-11.64V145.92c33,0,38,16.53,38,26.38,0,9.1-4.9,16.75-11.56,22.13,6.17,4.9,11.76,11.9,11.76,20.41,0,12.73-6.37,20.26-11.51,26.09C267.65,245.89,265.21,249.14,265.21,254.14Z"
              transform="translate(-245.34 -145.92)" style={{ fill: '#fff' }} />
            <path
              d="M300.61,254.14H286c0-11,5.85-17.67,10.55-23,4.38-5,7.85-9.05,7.85-16.18,0-4.62-8-11.46-14.24-13.56a7.42,7.42,0,0,1,0-14c5.91-2,13.77-8,13.77-15.06,0-2.88,1.44-11.64-23.56-11.64V145.92c34,0,38.2,16.53,38.2,26.38,0,9.1-4.8,16.75-11.46,22.13,6.18,4.9,11.81,11.9,11.81,20.41,0,12.73-6.34,20.26-11.49,26.09C303,245.89,300.61,249.14,300.61,254.14Z"
              transform="translate(-245.34 -145.92)" style={{ fill: '#fff' }} />
            <path
              d="M336,254.14H321.38c0-11,5.84-17.67,10.54-23,4.39-5,7.85-9.05,7.85-16.18,0-4.62-8-11.46-14.24-13.56a7.42,7.42,0,0,1,0-14c5.91-2,14.07-8,14.07-15.06,0-2.88-.26-11.65-23.26-11.64V145.92c33,0,37.9,16.53,37.9,26.38,0,9.1-4.95,16.75-11.61,22.13,6.18,4.89,11.74,11.9,11.74,20.41,0,12.73-6.38,20.26-11.53,26.09C338.47,245.9,336,249.14,336,254.14Z"
              transform="translate(-245.34 -145.92)" style={{ fill: '#fff' }} />
          </svg>
        </div>
        <div className="w-1/2 pl-0.5">
          <svg id="b76af123-dcb5-4a9a-9201-4123fc99ca0a" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 108 108">
            <rect width={108} height={108} style={{ fill: '#3d2c78' }} />
            <path
              d="M407.7,192.55a2.24,2.24,0,0,0-2.08-1.4A1.59,1.59,0,0,0,404,192.8c0,.64.48,1.19,1.44,1.66l2.68,1.31q3.33,1.65,3.33,4.51a5,5,0,0,1-2.63,4.43,4.92,4.92,0,0,1-2.63.75q-4.38,0-5.81-3.56l2.67-1.27a3,3,0,0,0,2.87,2.12,2.43,2.43,0,0,0,1.54-.54,2.29,2.29,0,0,0,.88-1.38,2.59,2.59,0,0,0-1.24-2.13,7.49,7.49,0,0,0-1.2-.74l-1.18-.62c-2.4-1.24-3.59-2.69-3.59-4.34a4.55,4.55,0,0,1,.6-2.15,4.36,4.36,0,0,1,4.12-2.42,4.82,4.82,0,0,1,4.41,2.8Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M426.43,188.86h3.08v16h-3.08V202.3a6.59,6.59,0,0,1-5.54,3.16,7.22,7.22,0,0,1-3.12-.65,7.6,7.6,0,0,1-3.46-3.29,9.79,9.79,0,0,1-1.11-4.69q0-5.24,3.94-7.42a7.66,7.66,0,0,1,3.75-1,6.64,6.64,0,0,1,5.54,3.24Zm.14,7.9a5.87,5.87,0,0,0-1.37-3.94,4.74,4.74,0,0,0-3.75-1.67,4.88,4.88,0,0,0-4.37,2.6,5.9,5.9,0,0,0-.78,3.08,5.68,5.68,0,0,0,2.57,5.19,4.41,4.41,0,0,0,2.38.73A4.8,4.8,0,0,0,425.2,201,6.42,6.42,0,0,0,426.57,196.76Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M433.53,188.86h3.06v2.29a5.6,5.6,0,0,1,5-2.72q5.59,0,5.6,6.86v9.62h-3.07v-9.69a4.94,4.94,0,0,0-.72-3,3.31,3.31,0,0,0-2.81-1.08c-2.64,0-4,1.66-4,5v8.77h-3.06Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M454.67,188.86v2.74q1.41-3.16,4.4-3.17a4.55,4.55,0,0,1,2.61.72l-1.4,2.87a3.22,3.22,0,0,0-2.06-.62q-3.56,0-3.55,5.36v8.15H451.6v-16Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M465.65,179.4a2,2,0,0,1,1.51.64,2.14,2.14,0,0,1,.61,1.54,1.93,1.93,0,0,1-.65,1.49,2.17,2.17,0,0,1-1.53.6,1.94,1.94,0,0,1-1.51-.64,2.27,2.27,0,0,1-.58-1.58,1.88,1.88,0,0,1,.66-1.44A2.13,2.13,0,0,1,465.65,179.4Zm-1.55,9.46h3.11v16H464.1Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path d="M475.39,195l6-6.11h4.07l-6.82,6.71,7.57,9.34h-4l-5.74-7.28-1.05,1.05v6.23h-3.08V175.19h3.08Z"
                  transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M498.29,188.86h3.07v9.24q0,4.46-2.85,6.51a7,7,0,0,1-9.77-2.41,7.82,7.82,0,0,1-.92-3.82v-9.52h3V198a5.51,5.51,0,0,0,.94,3.27,3.32,3.32,0,0,0,2.9,1.52c2.4,0,3.59-1.59,3.59-4.79Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M435.87,219.42h1.07V225a4.53,4.53,0,0,1-.45,2.26,2.06,2.06,0,0,1-1.93,1,2.46,2.46,0,0,1-1.3-.39l.54-.89a1.68,1.68,0,0,0,.87.3,1,1,0,0,0,.78-.37,1.77,1.77,0,0,0,.35-.81,7.71,7.71,0,0,0,.07-1.06Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M443.9,222.67h1V228h-1v-.87a2.22,2.22,0,0,1-1.85,1,2.31,2.31,0,0,1-1-.22,2.43,2.43,0,0,1-1.15-1.09,3.23,3.23,0,0,1-.38-1.57,2.59,2.59,0,0,1,1.32-2.47,2.51,2.51,0,0,1,1.25-.33,1.93,1.93,0,0,1,.63.12,2.13,2.13,0,0,1,1.22,1Zm0,2.63a2,2,0,0,0-.45-1.31,1.6,1.6,0,0,0-1.25-.56,1.62,1.62,0,0,0-1.46.87,2,2,0,0,0-.26,1,1.88,1.88,0,0,0,.85,1.73,1.45,1.45,0,0,0,.8.25,1.61,1.61,0,0,0,1.32-.6A2.12,2.12,0,0,0,443.94,225.3Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M448.79,222.67v.89h0a2,2,0,0,1,1-.87,2,2,0,0,1,.79-.17,2.3,2.3,0,0,1,2.17,1.33,3.07,3.07,0,0,1,.34,1.47,2.88,2.88,0,0,1-1.14,2.47,2.3,2.3,0,0,1-3.24-.64v3.92h-1v-8.4Zm3.38,2.68a2.05,2.05,0,0,0-.46-1.36,1.64,1.64,0,0,0-1.3-.56,1.55,1.55,0,0,0-1.42.87,2.08,2.08,0,0,0-.24,1,2.17,2.17,0,0,0,.43,1.38,1.53,1.53,0,0,0,1.28.6h0a1.43,1.43,0,0,0,.89-.28A1.9,1.9,0,0,0,452.17,225.35Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M459.79,222.67h1V228h-1v-.87a2.21,2.21,0,0,1-1.85,1,2.31,2.31,0,0,1-1-.22,2.48,2.48,0,0,1-1.15-1.09,3.22,3.22,0,0,1-.37-1.57,2.59,2.59,0,0,1,1.31-2.47,2.51,2.51,0,0,1,1.25-.33,2,2,0,0,1,.64.12,2.14,2.14,0,0,1,1.21,1Zm0,2.63a2,2,0,0,0-.45-1.31,1.58,1.58,0,0,0-1.25-.56,1.62,1.62,0,0,0-1.46.87,2,2,0,0,0-.26,1,1.89,1.89,0,0,0,.86,1.73,1.42,1.42,0,0,0,.79.25,1.6,1.6,0,0,0,1.32-.6A2.12,2.12,0,0,0,459.83,225.3Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
            <path
              d="M463.75,222.67h1v.76a1.87,1.87,0,0,1,1.65-.91c1.24,0,1.86.77,1.86,2.29V228h-1v-3.23a1.65,1.65,0,0,0-.24-1,1.09,1.09,0,0,0-.93-.36c-.88,0-1.32.56-1.32,1.67V228h-1Z"
              transform="translate(-396.85 -146.03)" style={{ fill: '#fff' }} />
          </svg>
        </div>
      </Link>
    </div>
  )
}