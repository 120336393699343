import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function FooterImage() {
  return (
    <div className="w-full mt-20">
      <StaticImage
        src="../../images/footer.jpg"
        alt=""
        layout="fullWidth"
        placeholder="blurred"
        formats={[ 'auto', 'webp', 'avif' ]}
      />
    </div>
  )
}