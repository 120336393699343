import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import Logo from './images/logo'
import { Link } from 'gatsby'

export default function TemporaryDrawer() {
  const [ state, setState ] = React.useState({
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="w-screen md:w-full"
    >
      <ul className="mt-10">
        <li>
          <Link to="/news" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">NEWS</span>
            <span className="block text-lg md:pl-6 font-jp">ニュース</span>
          </Link>
          <Link to="/message" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">MESSAGE</span>
            <span className="block text-lg md:pl-6 font-jp">ごあいさつ</span>
          </Link>
          <Link to="/advantage" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">ADVANTAGE</span>
            <span className="block text-lg md:pl-6 font-jp">こだわり</span>
          </Link>
          <Link to="/product" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">PRODUCT</span>
            <span className="block text-lg md:pl-6 font-jp">商品案内</span>
          </Link>
          <Link to="/company" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">COMPANY PROFILE</span>
            <span className="block text-lg md:pl-6 font-jp">会社概要</span>
          </Link>
          <Link to="/access" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">ACCESS</span>
            <span className="block text-lg md:pl-6 font-jp">アクセス</span>
          </Link>
          <Link to="/contact" className="w-full flex px-10 py-4 items-center flex-col md:flex-row">
            <span className="block font-medium text-2xl tracking-wider font-crimson">CONTACT</span>
            <span className="block text-lg md:pl-6 font-jp">お問い合わせ</span>
          </Link>
        </li>
      </ul>
    </div>
  )

  return (
    <div className="bg-white flex justify-end px-4 md:px-10 h-24 relative shadow-2xl sticky">
      <div className="w-28 md:w-32 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Logo />
      </div>
      {[ 'right' ].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} className="">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <div onClick={toggleDrawer(anchor, false)} className="mt-4 ml-3 cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="#fff" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
