import React from 'react'
import '@fontsource/noto-serif-jp'
import '@fontsource/noto-serif-jp/300.css'
import '@fontsource/noto-serif-jp/500.css'
import '@fontsource/noto-serif-jp/700.css'
import '@fontsource/noto-serif-jp/900.css'
import '@fontsource/roboto'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import "@fontsource/crimson-text"

import TemporaryDrawer from './menu'
import Footer from './footer'

export default function Layout({ children }) {
  return (
    <div className="antialiased font-light font-jp">
      <TemporaryDrawer />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}
